import Layout from "../../components/layout"
import React from "react"
import statute from "../../files/Statut_ZSP_w_Nidku.pdf"
import SEO from "../../components/seo"

function StatuteKindergarden() {
  return (
    <Layout menuType="kindergarden">
      <SEO title="Statut - ZSP Nidek" />
      <h1>Statut</h1>
      <a href={statute} download={"Statut_ZSP_w_Nidku.pdf"}>
        Statut ZSP w Nidku (377KB | pdf)
      </a>
    </Layout>
  )
}

export default StatuteKindergarden
